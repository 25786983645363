import React, {ChangeEvent, useEffect, useState} from 'react';

import OlMap from 'ol/Map';
import View from 'ol/View';
import {defaults} from 'ol/control';

import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource, BingMaps} from 'ol/source';
import Feature from 'ol/Feature';
import {Point} from 'ol/geom';
import {fromLonLat, transformExtent} from 'ol/proj';
import {Fill, Icon, RegularShape, Stroke, Style, Text} from 'ol/style';

import "./map.css";
import "ol/ol.css";
import {DeviceDto} from "../dto/DeviceDto";
import {ZoomSlider} from 'ol/control';
import {Landmark} from "../dbo/Landmark";
import {WeatherLocationDto} from "../dto/WeatherLocationDto";

interface MapProps {
    devices: DeviceDto[];
    weatherStations: WeatherLocationDto[];
    center: number[],
    zoom: number,
    onDeviceSelected: (deviceId: string) => void

}


const stroke = new Stroke({color: 'black', width: 2});
var fill = new Fill({color: 'red'});


const styles = {
    'square': new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: 10,
            angle: Math.PI / 4
        })
    }),
    'triangle': new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 3,
            radius: 10,
            rotation: Math.PI / 4,
            angle: 0
        })
    }),
    'star': new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 5,
            radius: 10,
            radius2: 4,
            angle: 0
        })
    }),
    'cross': new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: 10,
            radius2: 0,
            angle: 0
        })
    }),
    'x': new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: 10,
            radius2: 0,
            angle: Math.PI / 4
        })
    }),
    'image': function (text: string) {
        return new Style({
            image: new Icon({
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity: 1,
                src: '/plant-icon.svg',
                zIndex: 1,
                scale: 0.15
            }),
            text: new Text({
                text,
                scale: 1.3,
                offsetY: 40,
                padding: [5, 5, 5, 5],
                stroke: new Stroke({color: '#ffffff', width: 2}),
                // backgroundFill: new Fill({color: '#ffffff44'})
            })
        });
    }
};


function makeInitialMap(onDeviceSelected: (deviceId: string) => void) : OlMap {
    const mapId = 'map';
    // Don't make the map two times
    if ((document as any).getElementById(mapId).children.length > 0) {
        return;
    }
    const map = new OlMap({
        controls: defaults(),
        target: mapId,
        layers: [
            new TileLayer({
                source: new BingMaps({
                    key: 'AiBw1v7ViMnbHBqIP9Jk72AYBLMC2CIGwdT6vkwhcbMswNURM6U7v1nl8XV2oQAr',
                    imagerySet: 'Aerial'
                }),
            }),
        ],
        view: new View({
            //center: props.center,
            center: fromLonLat([85.45, -60.69]),
            zoom: 17,
            maxZoom: 19
        })
    });
    const zoomslider = new ZoomSlider(null);
    (map as any).addControl(zoomslider);
    return map;

}

let clickHandler: EventsKey;

function refreshCallback(map: OlMap, onDeviceSelected: (deviceId: string) => void) {
    // Workaround because for "reasons"
    // Map doesn't export the event management function
    if (clickHandler) {
        // @ts-ignore
        map.un('click', clickHandler.listener);
    }
    //console.log("Setting click handler", onDeviceSelected);
    clickHandler = map.on('click', (event: any) => {
        let done = false;
        map.forEachFeatureAtPixel(event.pixel, function (feature: any, layer: any) {
            if (!done) {
                if (feature.deviceId) {
                    onDeviceSelected(feature.deviceId);
                }
                done = true;
            }
        });
    })
}


function computeExtent(devices: DeviceDto[]) {
    if (devices.length === 0) {
        console.debug("No devices, extent is null");
        return [Infinity, Infinity, -Infinity, -Infinity];
    }
    const maxLat = Math.max.apply(null, devices.map(d => d.gps[0]));
    const maxLon = Math.max.apply(null, devices.map(d => d.gps[1]));
    const minLat = Math.min.apply(null, devices.map(d => d.gps[0]));
    const minLon = Math.min.apply(null, devices.map(d => d.gps[1]));
    const result = transformExtent([minLat, minLon, maxLat, maxLon], 'EPSG:4326', 'EPSG:3857');
    //const result = fromLonLat([minLon, minLat,  maxLon, maxLat]);
    console.debug("Computed extent for devices is", result);
    return result;
}

function makeSource(landmark: Landmark[]) {
    const features = landmark.filter(d => d.gps)
        .map(d => {
            const feature = new Feature(new Point(fromLonLat(d.gps), null));
            if ( d.type === 'device') {
                (feature as any).deviceId = d.id;
            }
            feature.setStyle(styles.image(d.description));
            return feature;
        });
    const source = new VectorSource({
        features
    });
    return source;
}
function makeMarkers(landmarks: Landmark[]) {
    const source = makeSource(landmarks);
    const layer = new VectorLayer({
        source,
        style: styles.image('')
    });
    return {source, layer};
}

function setMousePointer(map: any) {
    map.on('pointermove', function (e: any) {
        var pixel = map.getEventPixel(e.originalEvent);
        var hit = map.hasFeatureAtPixel(pixel);
        map.getViewport().style.cursor = hit ? 'pointer' : '';
    });
}

function deviceToLandmark(device: DeviceDto): Landmark {
    return {
        id: device.id,
        type: 'device',
        description: device.description,
        gps: device.gps
    }
}

function weatherLocationToLandmark(wl: WeatherLocationDto): Landmark {
    return {
        id: wl.id,
        description: wl.description,
        type: 'weather-station',
        gps: wl.coordinates.split(' ').map(Number)
    }
}

export function Map(props: MapProps) {
    const [map, setMap] = useState<OlMap>(null);
    const [featureLayer, setFeatureLayer] = useState<VectorLayer>(null);

    const landmarks = [...props.devices.map( deviceToLandmark ), ...props.weatherStations.map( weatherLocationToLandmark )];

    const [visibleDevices, setVisibleLandmarks] = useState<Landmark[]>(landmarks);
    console.log("Visible devices:", visibleDevices);
    // Initial load of the map
    useEffect(() => {
        console.log(`Setting up initial map centered`);
        if (!map && props.devices.length > 0) {
            const m = makeInitialMap(props.onDeviceSelected);
            setMousePointer(m);
            setMap(m);
        }
    }, [props.onDeviceSelected, props.devices.length, props.weatherStations.length, map]);
    if (map) {
        refreshCallback(map, props.onDeviceSelected);
    }

    useEffect( () => {
        console.log(`Refilter devices`);
        const filter = document.getElementById('map-device-filter')?.nodeValue || '';

        console.log("Setting visible landmarks to", landmarks);
        setVisibleLandmarks( applyFilter( filter, landmarks ));
    }, [props.devices, props.weatherStations])

    // Update the markers
    useEffect(() => {
        if (map) {
            console.log(`Refresh markers`);
            // remove previous device layer, if needed
            if (featureLayer) {
                // we already have a feeature layer, use it
                const source = makeSource(visibleDevices);
                featureLayer.setSource(source);
            } else {
                const newLayer = makeMarkers(visibleDevices);
                setFeatureLayer( newLayer.layer );
                map.addLayer(newLayer.layer);
            }
            const center = fromLonLat(props.center);
            // console.log(`Centering map on ${props.center} (${center})`);
            map.getView().setCenter(center);
            if (visibleDevices.length > 0) {
                const extent = computeExtent(props.devices)
                map.getView().fit(extent, map.getSize());
            }
        }
    });

    function applyFilter(text: string, landmarks: Landmark[]) : Landmark[] {
        console.log(`Apply filter ${text}`);
        return landmarks.filter( l => {
            return l.description.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }

    function onFilterChanged(event: ChangeEvent<HTMLInputElement>) {
        const filter = event.target.value;
        setVisibleLandmarks( applyFilter(filter, landmarks ));
    }

    return (<div className='map-container'>
        <input className='map-filter' placeholder='Filter devices...' id='map-device-filter' type='text' onChange={onFilterChanged} ></input>
        <div id="map"></div>
    </div>);
}
