import React, {MutableRefObject} from 'react';
import {PlantStatus} from "./PlantStatus";
import {DeviceDto} from "../dto/DeviceDto";
import {LeafSensorData} from "../dbo/LeafSensorData";
import {WeatherData} from "../dbo/WeatherData";
import {Chart} from "./Chart";
import {RainChart} from "./RainChart";
import {ZonedDateTime} from '@js-joda/core';
import {DeviceData} from "./DeviceData";
import {WeatherLine} from "./WeatherLine";
import {ChartDataElement, DeviceChartData} from "../dbo/ChartDataElement";
import {PlantDto} from "../dto/PlantDto";
import {ProfileDto} from "../dto/ProfileDto";
import {indexBy} from "../utils/Utils";
import {WeatherLocationDto} from "../dto/WeatherLocationDto";

interface PlantSensorDetailsProps {
    plant: PlantDto;
    profile: ProfileDto;
    onPlantSelected: (plant: PlantDto) => void;
    device: DeviceDto;
    leafSensorData: LeafSensorData[];
    weatherData: WeatherData[];
    from: ZonedDateTime;
    to: ZonedDateTime;
    isAdmin: boolean;
    jwt: string;
    chartData: DeviceChartData;
    scrollReference: MutableRefObject<null>;
    onWeatherStationShow: (wl: WeatherLocationDto | undefined) => void;
}

function renderCharts(data: LeafSensorData[], weatherData: WeatherData[], profile: ProfileDto,
                      chartData: { [key: string]: ChartDataElement[] | undefined }, xMin: ZonedDateTime, xMax: ZonedDateTime,
                      isAdmin: boolean, plant: PlantDto) {
    const sensors = indexBy(profile.sensors, 'id');
    return <section>
        <RainChart weatherData={weatherData} xMin={xMin} xMax={xMax} label={sensors['rain1h']?.description}/>
        {profile.sensors.map(sensor => {
            const fieldData = chartData[sensor.id];
            if (fieldData !== undefined) {
                return <div key={sensor.id} className="charts">
                    <Chart title={sensor.description} data={fieldData}
                           syncId={'chartSyncId'}
                           xMin={xMin.toEpochSecond()}
                           xMax={xMax.toEpochSecond()}
                           yMin={sensor.yMin}
                           yMax={sensor.yMax}
                            hasBaseline={plant.baselineCalculated || sensor.description.toLowerCase().startsWith("dehydration") === false }
                    />
                </div>;
            } else {
                console.error(`Data for field ${sensor.id} is missing!`, chartData);
                return null;
            }
        })}

    </section>;
}

function renderDeviceData(plant: PlantDto, profile: ProfileDto, device: DeviceDto, data: LeafSensorData[], isAdmin: boolean, jwt: string) {
    if (isAdmin) {
        return <DeviceData plant={plant} profile={profile} data={data} jwt={jwt}
                           onlyDownload={false}/>;
    } else {
        return <DeviceData plant={plant} profile={profile} data={data} jwt={jwt}
                           onlyDownload={true}/>;
    }
}

export function LeafSensorDetails(props: PlantSensorDetailsProps) {
    const {
        plant,
        device,
        leafSensorData,
        weatherData,
        from,
        to,
        jwt,
        isAdmin,
        chartData,
        scrollReference
    } = props;
    return <div>
        <PlantStatus plant={plant} status={leafSensorData[0]} scrollReference={scrollReference}
                     onPlantSelected={props.onPlantSelected}
                     profile={props.profile}
                     onWeatherStationShow={props.onWeatherStationShow}
                     device={device}/>
        <WeatherLine data={weatherData} from={from} to={to}/>
        {/*function renderCharts(data: LeafSensorData[], weatherData: WeatherData[], rainGaugeData: RainDataDto[],*/}
        {/*chartData: { [key: string]: ChartDataElement[] }, xMin: ZonedDateTime, xMax: ZonedDateTime,*/}
        {/*isAdmin: boolean,*/}
        {/*labels: { [label:string]: string }) {*/}
        {renderCharts(leafSensorData, weatherData, props.profile, chartData, from, to, isAdmin, plant)}
        {renderDeviceData(plant, props.profile, device, leafSensorData, isAdmin, jwt)}

    </div>;
}
