import {Map} from "../Map";
import {Menu} from "./Menu";
import {ALL_RANGE_WIDTHS, RangeWidth} from "../../dbo/RangeWidth";
import {AppState, CurrentData, LoginInformation} from "../../state";
import {DeviceDto} from "../../dto/DeviceDto";
import {LeafSensorDetails} from "../LeafSensorDetails";
import {ZonedDateTime} from '@js-joda/core';
import React, {MutableRefObject, useEffect, useState} from 'react';
import {
    getUserPageInitialState,
    loadPlantData,
    setLuminosityFilter,
    setSelectedDate,
    USER_PAGE_EMPTY_STATE,
    UserPageState
} from "../../state/UserPageState";
import {DeviceChartData} from "../../dbo/ChartDataElement";
import './UserPage.css';
import {DateService} from "../../service/DateService";
import {PlantDto} from "../../dto/PlantDto";
import {PlantService} from "../../service/PlantService";
import {ProfileService} from "../../service/ProfileService";
import {ProfileDto} from "../../dto/ProfileDto";
import {useAsync} from "../../hooks/useAsync";
import {DeviceService} from "../../service/DeviceService";
import {WeatherLocationDto} from "../../dto/WeatherLocationDto";

interface UserPageProps {
    state: AppState,
    onRangeSelected: (rangeWidth: RangeWidth) => void,
    dataStartRef: MutableRefObject<null>,
    selectedCustomerId: string
}


export default function UserPage(props: UserPageProps) {
    const {dataStartRef} = props;
    const {state} = props;
    let [userPageState, setState] = useState(USER_PAGE_EMPTY_STATE);
    useEffect(() => {
        getUserPageInitialState().then(state => {
            setState(state);
        });
    }, []);

    useEffect(() => {
        console.log("Customer ID:", props.selectedCustomerId);
        if (props.selectedCustomerId) {
            DeviceService.getByCustomerId(props.selectedCustomerId, true).then(devices => {
                console.log("DEVICES:", devices);
                setState(state => {
                    return {...state, devices};
                })
            })
        } else {
            setState(state => {
                return {...state, devices: []};
            })
        }
    }, [props.selectedCustomerId])

    let xMin: ZonedDateTime | undefined;
    let xMax: ZonedDateTime | undefined;

    let to = new Date();
    let from = DateService.getStartDate(to, state.rangeWidth);

    if (userPageState.currentData) {
        from = userPageState.currentData.from;
        to = userPageState.currentData.to;
    }
    from = DateService.getBeginningOfDay(from);
    to = DateService.getBeginningOfDay(to);
    xMin = ZonedDateTime.parse(from.toISOString());
    xMax = ZonedDateTime.parse(to.toISOString());


    const profileId = userPageState.currentData?.currentPlant?.deviceProfileId;

    async function loadProfile(): Promise<ProfileDto | undefined> {
        const profileId = userPageState.currentData?.currentPlant?.deviceProfileId
        if (profileId) {
            return ProfileService.getProfileById(profileId);
        } else {
            return undefined;
        }
    }


    const profileAsync = useAsync({
        promiseFn: loadProfile,
        waitChange: true,
        watch: profileId
    });

    let profile: ProfileDto | undefined;

    switch (profileAsync.status) {
        case 'initial':
        case 'pending':
        case 'rejected':
            profile = undefined;
            break;
        case 'fulfilled':
            profile = profileAsync.data;
    }

    async function loadData(userPageState: UserPageState, device: DeviceDto, rangeWidth: RangeWidth, focus: boolean): Promise<UserPageState> {
        const sameDevice = userPageState.currentData?.currentDevice.id === device.id;
        const plant = userPageState?.currentData?.currentPlant;
        let plantId;
        if (plant && sameDevice) {
            plantId = plant.id;
        } else {
            plantId = device.plantId;
        }
        console.log("Loading data for", plantId);
        const newState = await loadPlantData(userPageState, device, plantId, rangeWidth);
        setState(newState);

        if (dataStartRef.current !== null && focus) {
            (dataStartRef.current as any).scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
        return newState;
    }

    async function onPlantSelected(plant: PlantDto) {
        console.log("Selected plant", plant);
        if (!plant.id) {
            console.warn("Using plant without id");
            return;
        }
        if (!userPageState.currentData) {
            return;
        }
        const lastDate = await PlantService.getLastDate(plant.id);
        console.log(`Most recent data for plants "${plant.description} is at ${lastDate}`);
        const currentData = {...userPageState.currentData, currentPlant: plant, selectedDate: lastDate || new Date()};
        let newState: UserPageState = {...userPageState, currentData};
        if (newState.currentData) {
            // we know it is always true, but TS doesn't
            newState = await loadData(newState, newState.currentData.currentDevice, state.rangeWidth, false);
            if (lastDate) {
                setState(setSelectedDate(newState, DateService.getBeginningOfDay(lastDate), state.rangeWidth));
            }
        }
    }

    function toggleWeatherStationVisibility( wl :WeatherLocationDto | undefined) {
        // Fixme, what if we have more than one ?
        if (wl) {
            console.log("Showing weather station", wl);
            setState( s => ({...s, weatherStations: [wl]}));
        } else {
            setState( s => ({...s, weatherStations: []}));
            console.log("Hiding weather station location");
        }

    }

    function renderData(plantData: CurrentData | null, chartData: DeviceChartData) {
        if (plantData === null || xMin === undefined || xMax === undefined || profile === undefined) {
            return <h2>No Device Selected</h2>;
        } else if ( plantData.currentPlant != null) {
            const {
                currentPlant,
                currentDevice,
                leafSensorData,
                weatherData,
            } = plantData;
            const isAdmin: boolean = (state.login as LoginInformation).hasRole('admin');
            return <LeafSensorDetails
                profile={profile}
                device={currentDevice}
                plant={currentPlant}
                from={xMin}
                to={xMax}
                isAdmin={isAdmin}
                leafSensorData={leafSensorData}
                weatherData={weatherData}
                chartData={chartData}
                scrollReference={dataStartRef}
                onPlantSelected={onPlantSelected}
                onWeatherStationShow={toggleWeatherStationVisibility}
                jwt={(state.login as LoginInformation).jwt}
            />
        } else {

            return <div>No data for the selected range</div>;
        }
    }

    function onDeviceSelected(deviceId: string) {
        // FIXME maybe optimize this ...
        for (const device of userPageState.devices) {
            if (device.id === deviceId) {
                console.debug("Device selected", device);
                loadData(userPageState, device, state.rangeWidth, true).then();
            }
        }
    }

    async function onRangeSelected(range: RangeWidth) {
        console.log("Selected range", range);
        if (userPageState.currentData && userPageState.currentData.currentDevice) {
            await loadData(userPageState, userPageState.currentData.currentDevice, range, false);
        }
        props.onRangeSelected(range);
    }


    async function onDateSelected(date: Date) {
        console.info("Setting new date to", date);
        const newState = setSelectedDate(userPageState, date, state.rangeWidth);
        if (newState.currentData) {
            await loadData(newState, newState.currentData.currentDevice, state.rangeWidth, false);
        } else {
            setState(newState);
        }
    }

    async function onFilterLuminosityToggled(filter: boolean) {
        if (userPageState.currentData?.currentPlant?.deviceProfileId) {
            const profile = await ProfileService.getProfileById(userPageState.currentData.currentPlant.deviceProfileId);
            setState(setLuminosityFilter(userPageState, profile, !userPageState.filterLuminosity));

        }
    }


    let selectedDate = null;
    if (userPageState.currentData) {
        selectedDate = userPageState.currentData.selectedDate;
    }
    return (
        <div className="user-page">
            <div className="top">
                <Map devices={userPageState.devices} center={userPageState.mapConfiguration.center}
                     weatherStations={userPageState.weatherStations}
                     onDeviceSelected={onDeviceSelected}
                     zoom={userPageState.mapConfiguration.initialZoom}/>
                <Menu ranges={ALL_RANGE_WIDTHS}
                      onRangeSelected={onRangeSelected}
                      onDateSelected={onDateSelected}
                      onFilterLuminosityToggled={onFilterLuminosityToggled}
                      selectedDate={selectedDate}
                      filterLuminosity={userPageState.filterLuminosity}
                      selectedRangeId={state.rangeWidth.id}/>
                {/*{ state.currentData && state.currentData.currentDevice && state.currentData.currentPlant &&*/}
                {/*    <SideMenu device={state.currentData.currentDevice}*/}
                {/*          plant={state.currentData.currentPlant}*/}
                {/*          onEntryToggled={onEntryToggled}/>*/}
                {/*}*/}
                {renderData(userPageState.currentData, userPageState.chartData)}
            </div>
        </div>
    );
}
