import {UserDto} from "../../dto/UserDto";
import React, { useState } from "react";

export interface UserProps {
    className?: string;
    user: UserDto;
    onSave: (user: UserDto) => void;
    editingEnabled: boolean;
    startEditing?: boolean;
}

interface State {
    editing: boolean
    editedUser: UserDto | undefined,
}

function getInitialState(user: UserDto, startEditing: boolean | undefined ): State {
    if (startEditing) {
        return {
            editing: true,
            editedUser: user
        };
    } else {
        return {
            editing: false,
            editedUser: undefined
        }
    }
}


export function User(props: UserProps) {
    const [state, setState] = useState(getInitialState(props.user, props.startEditing));
    function onEditClicked() {
        setState({...state, editing: true, editedUser: props.user});
    }

    function onCancelClicked() {
        setState({...state, editing: false, editedUser: undefined});
    }

    async function onSaveClicked() {
        if (!state.editedUser) {
            return;
        }
        await props.onSave(state.editedUser as UserDto);
        setState({...state, editedUser: undefined, editing: false});
    }

    function onFieldChanged(field: string, value: string) {
        const editedUser = {...state.editedUser, [field]: value } as UserDto;
        console.log("Changing field", field, "to", value);
        console.log("New user:", editedUser);
        setState({...state, editedUser});
    }

    function toggleActive() {
        const editedUser = {...state.editedUser} as UserDto;
        editedUser.active = !editedUser.active;
        setState({...state, editedUser});
    }

    function hasRole(user: UserDto, role: string) {
        return user.roles.indexOf(role) >= 0;
    }

    function toggleRole(role: string) {
        const editedUser = {...state.editedUser} as UserDto;
        if (hasRole(editedUser, role)) {
            editedUser.roles = editedUser.roles.filter( r => r !== role);
        } else {
            editedUser.roles = [...editedUser.roles, role];
        }
        setState({...state, editedUser});
    }

    if (state.editing && state.editedUser) {
        const user = state.editedUser;
        return <div className={props.className}>
            <span>{user.id}</span>
            <input onChange={e => onFieldChanged('email', e.target.value)} value={user.email}/>
            <input onChange={e => onFieldChanged('name', e.target.value)} value={user.name}/>
            <input onChange={e => onFieldChanged('surname', e.target.value)} value={user.surname}/>
            <input type='checkbox' checked={user.active} onChange={toggleActive}/>
            <span>
                <input onChange={() => toggleRole('admin')} name="role-admin" type='checkbox'
                       checked={hasRole(user, 'admin')}/><label htmlFor="role-admin">admin</label>
                <input onChange={() => toggleRole('user')} name="role-user" type='checkbox'
                       checked={hasRole(user, 'user')}/><label htmlFor="role-user">user</label>
                <input onChange={() => toggleRole('backoffice')} name="role-backoffice" type='checkbox'
                       checked={hasRole(user, 'backoffice')}/><label htmlFor="role-backoffice">backoffice</label>
            </span>
            <input onChange={e => onFieldChanged('password', e.target.value)} placeholder='new password'></input>
            <input type="date" onChange={e => onFieldChanged('endDate', e.target.value)} placeholder='expire date'></input>
            <span>
                <button onClick={onCancelClicked}>Cancel</button>
                <button onClick={onSaveClicked}>Save</button>
            </span>
        </div>;
    } else {
        const user = props.user;
        return <div className={props.className}>
            <span>{user.id}</span>
            <span>{user.email}</span>
            <span>{user.name}</span>
            <span>{user.surname}</span>
            <span>{user.active ? '✓' : ''}</span>
            <span>{(user.roles || []).join(", ")}</span>
            <span></span>
            <span>{user.endDate && new Date(user.endDate).toLocaleDateString()}</span>
            {props.editingEnabled ? <button onClick={onEditClicked}>Edit</button> : <span></span>}
        </div>;
    }
}
