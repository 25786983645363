import {DeviceDto} from "../dto/DeviceDto";
import {LeafSensorData} from "../dbo/LeafSensorData";
import {PlantDto} from "../dto/PlantDto";
import {WeatherData} from "../dbo/WeatherData";
import {ALL_RANGE_WIDTHS, RangeWidth} from "../dbo/RangeWidth";
import {UserPageState} from "./UserPageState";
import {LoginService} from "../service/LoginService";


export class LoginInformation {
    readonly username: string;
    readonly jwt: string;
    readonly roles: string[];
    readonly customerId: string;

    constructor(username: string, jwt: string, roles: string[], customerId: string) {
        this.username = username;
        this.jwt = jwt;
        this.roles = roles;
        this.customerId = customerId;
    }

    hasRole(role: string): boolean {
        return this.roles.indexOf(role) >= 0;
    }


}

export interface LoginError {
    message: string;
}

export interface CurrentData {
    readonly currentDevice: DeviceDto,
    readonly currentPlant: PlantDto | null,
    // Selected date
    readonly selectedDate: Date;
    // Ending date of the data to show
    readonly from: Date;
    // Beginning date of the data to show
    readonly to: Date;
    readonly weatherData: WeatherData[];
    readonly leafSensorData: LeafSensorData[],
}

export interface AppState {
    readonly selectedCustomerId: string | undefined
    readonly login: LoginInformation | null,
    readonly error: LoginError | null,
    readonly count: number;
    readonly userPage: UserPageState | null;
    readonly rangeWidth: RangeWidth

}

export function initialState(): AppState {
    const login = LoginService.isLoggedIn() ? LoginService.getLoginInformation() : null;
    return {
        login: login,
        selectedCustomerId: login?.customerId,
        error: null,
        count: 0,
        userPage: null,
        rangeWidth: ALL_RANGE_WIDTHS[1]
    };
}
