import {DeviceDto} from "../dto/DeviceDto";
import {DeviceChartData, makeChartDataForLeafSensor} from "../dbo/ChartDataElement";
import {CurrentData} from "./index";
import {RangeWidth} from "../dbo/RangeWidth";
import {LeafSensorData} from "../dbo/LeafSensorData";
import {WeatherData} from "../dbo/WeatherData";
import {PlantService} from "../service/PlantService";
import {DateService} from "../service/DateService";
import {ProfileDto} from "../dto/ProfileDto";
import {ProfileService} from "../service/ProfileService";
import {WeatherLocationDto} from "../dto/WeatherLocationDto";

export type MapConfiguration = {
    readonly center: number[],
    readonly initialZoom: number
}


export function computeMapConfiguration(devices: DeviceDto[]): MapConfiguration {
    if (devices.length === 0) {
        return {
            initialZoom: 4,
            center: [0, 0]
        }
    }
    // ignore devices without position
    devices = devices.filter(d => d.gps !== null && d.gps.length === 2);
    const longitude = devices.map(d => d.gps[0]).reduce((x, y) => x + y, 0) / devices.length;
    const latitude = devices.map(d => d.gps[1]).reduce((x, y) => x + y, 0) / devices.length;
    return {
        initialZoom: 4,
        center: [longitude, latitude]
    }

}


export interface UserPageState {
    readonly devices: DeviceDto[],
    readonly chartData: DeviceChartData,
    readonly currentData: CurrentData | null,
    readonly mapConfiguration: MapConfiguration,
    readonly filterLuminosity: boolean;
    readonly weatherStations: WeatherLocationDto[];
}

export const USER_PAGE_EMPTY_STATE: UserPageState = {
    devices: [],
    chartData: {},
    currentData: null,
    mapConfiguration: {
        center: [0, 0],
        initialZoom: 10
    },
    weatherStations: [],
    filterLuminosity: false
};

export async function getUserPageInitialState(): Promise<UserPageState> {
    let devices: DeviceDto[] = [];
    try {

        devices = []
    } catch (e) {
        console.log("Unable to get my devices:", e);
    }

    const mapConfiguration = computeMapConfiguration(devices);
    return {...USER_PAGE_EMPTY_STATE, devices, mapConfiguration};
}

export function setSelectedDate(state: UserPageState, date: Date, rangeWidth: RangeWidth): UserPageState {
    if (state.currentData === null) {
        return state;
    }
    const to = DateService.getEndOfDay(date);
    const from = DateService.getStartDate(to, rangeWidth);
    const currentData = {...state.currentData, from, to, selectedDate: DateService.getBeginningOfDay(date)};
    return {...state, currentData};
}


export async function loadPlantData(userPageState: UserPageState, device: DeviceDto, plantId: string | null | undefined, rangeWidth: RangeWidth):
    Promise<UserPageState> {
    let selectedDate = new Date();
    if (userPageState.currentData) {
        selectedDate = userPageState.currentData.selectedDate;
    }
    selectedDate = DateService.getBeginningOfDay(selectedDate);
    let to = DateService.getEndOfDay(selectedDate);
    let from = DateService.getStartDate(to, rangeWidth);
    console.log("Loading data from", from, "to", to);
    let data: LeafSensorData[] = [];
    let weatherData: WeatherData[] = [];
    let plant = null;
    if (plantId) {
        data = await PlantService.getPlantData(plantId, from, to);
        weatherData = await PlantService.getWeatherDataByPlantId(plantId, from);
        plant = await PlantService.getById(plantId);
    }

    // FIXME: I know we have this. but first we should remove all the rain gauge related stuff
    const profileId = plant?.deviceProfileId as string;
    const profile = await ProfileService.getProfileById(profileId);
    const chartData = makeChartDataForLeafSensor(data, profile, userPageState.filterLuminosity);

    const plantData: CurrentData = {
        currentDevice: device,
        currentPlant: plant,
        leafSensorData: data,
        weatherData,
        selectedDate,
        from,
        to
    };
    const newState = setSelectedDate({
        ...userPageState,
        currentData: plantData,
        chartData: chartData
    }, selectedDate, rangeWidth);
    return newState;
}

export function setLuminosityFilter(state: UserPageState, profile: ProfileDto, filterLuminosity: boolean) {
    if (state.currentData) {
        const chartData = makeChartDataForLeafSensor(state.currentData.leafSensorData, profile, filterLuminosity);
        return {...state, filterLuminosity, chartData};
    } else {
        return state;
    }
}
