import React, {useEffect, useState, ChangeEvent, MouseEventHandler} from 'react';
import {CustomerDto} from "dto/CustomerDto";
import {UserDto} from "dto/UserDto";
import {Customer} from "./Customer";
import {getCustomersByAncestorId, saveCustomer} from "../../service/CustomerService";
import './BackofficePage.css';
import {getUsers, saveUser} from "../../service/UserService";


function sortOn(data: any[], key: string) {
    data.sort(function (a: any, b: any) {
        const ak: any = a[key];
        const bk: any = b[key];
        if (ak < bk) {
            return -1;
        } else if (ak > bk) {
            return 1;
        }
        return 0;
    });
}


export interface CustomerPageState {
    customers: CustomerDto[];
    filteredCustomers: CustomerDto[];
    usersByCustomerId: { [customerId: string]: UserDto[] };
    editedCustomer: CustomerDto | undefined;
    initDone: boolean;
    showInactiveUsers: boolean;
}

const INITIAL_STATE: CustomerPageState = {
    customers: [],
    filteredCustomers: [],
    usersByCustomerId: {},
    editedCustomer: undefined,
    initDone: false,
    showInactiveUsers: false
}

function filterCustomers( customers: CustomerDto[], filter: string): CustomerDto[] {
    return customers.filter( c => c.description.toLowerCase().indexOf(filter) >= 0);
}


export interface Props {
    customerId: string;
}

export function CustomerPage(props: Props) {
    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
        async function loadCustomers() {
            const customers = await getCustomersByAncestorId(props.customerId);
            sortOn(customers, 'id');
            const users = await getUsers();
            const usersByCustomerId: { [id: string]: UserDto[] } = {};

            for (const user of users) {
                const customerId = user.customerId;
                if (!usersByCustomerId[customerId]) {
                    usersByCustomerId[customerId] = [];
                }
                usersByCustomerId[customerId].push(user);
            }
            setState(s => ({...s, customers, filteredCustomers: customers, initDone: true, usersByCustomerId}));
        }
        console.log("Checking customers...");
        if (props.customerId || !state.initDone) {
            console.info("Loading customers");
            loadCustomers().then();

        }
    }, [state.initDone, props.customerId]);

    function onNewCustomerClicked() {
        const editedCustomer: CustomerDto = {
            description: '',
            active: true,
            id: '',
            owner: props.customerId
        }
        setState({...state, editedCustomer});
    }

    async function onSaveClicked(customer: CustomerDto) {
        console.info("Saving customer", customer);
        await saveCustomer(customer);
        setState(s => ({...s, initDone: false}));
    }

    async function onSaveNewClicked(customer: CustomerDto) {
        await onSaveClicked(customer);
        setState(s => ({...s, editedCustomer: undefined}));
    }

    async function onUserSaveClicked(user: UserDto) {
        console.info("Saving user", user);
        await saveUser(user);

        setState({...state, initDone: false});
    }

    function unFilterChanged(event: ChangeEvent<HTMLInputElement>) {
        const filteredCustomers = filterCustomers(state.customers, event.target.value);
        setState(state => ({...state, filteredCustomers}))
        console.log("event", event);
    }

    function getCustomerUsers(customerId: string) {
        const result: UserDto[] = state.usersByCustomerId[customerId] || [];
        if (state.showInactiveUsers) {
            return result;
        } else {
            return result.filter( user => user.active);
        }
    }

    function toggleInactiveUsers( e: MouseEventHandler<HTMLInputElement>) {
        console.log("E");
        setState( s => {
            return {...s, showInactiveUsers: !s.showInactiveUsers};
        })
    }
    return <div>
        <div style={{"margin": "1em"}} className="row">

            <input type="search" placeholder="Filter customers" onChange={unFilterChanged}/>
            <label ><input type="checkbox" onClick={toggleInactiveUsers }/>Show inactive users </label>
            <span className="spacer"/>
            <button onClick={onNewCustomerClicked}>New Customer</button>
        </div>
        <div className="backoffice-customer-list">
            {state.editedCustomer ? <Customer onSaveClicked={onSaveNewClicked}
                                              onUserSaveClicked={onUserSaveClicked}
                                              customer={state.editedCustomer}
                                              startEditing={true} users={[]}/> : null}
            {state.filteredCustomers.map(c => <Customer
                key={c.id}
                onSaveClicked={onSaveClicked}
                onUserSaveClicked={onUserSaveClicked}
                customer={c}
                startEditing={false}
                users={getCustomerUsers(c.id)}/>)}
        </div>
    </div>;
}
