import React, {ChangeEvent, useState} from "react";
import {LeafSensorService} from "../../service/LeafSensorService";
import {Card} from "../Card";
import {GreenRed} from "../GreenRed";
import {ColumnOptions, SortingInfo, Table} from "../Table";
import {getCustomerById, getCustomersByAncestorId} from "../../service/CustomerService";
import {sortAlphaNum} from "../../utils/Utils";
import {PlantService} from "../../service/PlantService";
import {useAsync} from "../../hooks/useAsync";
import {Spinner} from "../Spinner";
import {ProfileService} from "../../service/ProfileService";
import {LeafSensorWithPlantDto} from "../../dto/LeafSensorWithPlantDto";
import {ProfileDto} from "../../dto/ProfileDto";
import {CustomerDto} from "../../dto/CustomerDto";

interface DevicePageProps {
    customerId: string;
}

export interface LeafSensorRowData {
    id: string,
    name: string,
    description: string,
    gps: number[],
    regDate: Date,
    notificationEnabled: boolean,
    customer: string,
    customerId: string,
    plantId: string,
    connected: boolean;
    profileId: string;
    hidden: boolean;
    move: () => void;
}

function mapLeafSensor(leafSensor: LeafSensorWithPlantDto, customerName: string): LeafSensorRowData {
    return {
        id: leafSensor.id,
        name: leafSensor.name,
        customer: customerName,
        customerId: leafSensor.customerId,
        gps: leafSensor.gps,
        description: leafSensor.description,
        notificationEnabled: leafSensor.notificationEnabled,
        regDate: leafSensor.regDate,
        plantId: leafSensor.plantId,
        connected: leafSensor.connected,
        profileId: leafSensor.plant.deviceProfileId,
        hidden: leafSensor.plant.hidden,
        move: () => undefined
    };
}


async function getProfiles(customerId: string): Promise<{ [id: string]: ProfileDto }> {
    const result: { [id: string]: ProfileDto } = {};
    const profiles = await ProfileService.getProfilesByChildCustomerId(customerId);
    for (const profile of profiles) {
        // we know we have the ID
        result[profile.id as string] = profile;
    }
    return result;
}

async function getLeafSensors(): Promise<LeafSensorRowData[]> {
    console.log("Loading leaf sensors");
    const sensors = await LeafSensorService.getMyLeafSensors(true, true) as LeafSensorWithPlantDto[];
    const result = [];


    const customers : { [id: string]: Promise<CustomerDto> } = {};

    for (const sensor of sensors) {
        if (sensor.customerId && !customers[sensor.customerId]) {
            customers[sensor.customerId] = getCustomerById(sensor.customerId);
        }
    }

    for (const sensor of sensors) {
        const customer = await customers[sensor.customerId];
        const description = sensor.customerId ? customer.description : '';
        result.push(mapLeafSensor(sensor, description));
    }
    return result;
}

interface DevicePageState {
    showHidden: boolean;
    devices: LeafSensorRowData[];
    sort?: SortingInfo<LeafSensorRowData>;
}

const initialState: DevicePageState = {
    showHidden: false,
    devices: [],
};

export function DevicePage(props: DevicePageProps) {
    const [state, setState] = useState(initialState);


    function handleSortChanged(sortEvent: SortingInfo<LeafSensorRowData>) {
        const cmp = (a: any, b: any) => sortAlphaNum(a[sortEvent.column], b[sortEvent.column]);
        state.devices.sort(cmp);
        if (sortEvent.order === 'desc') {
            state.devices.reverse();
        }
        setState({...state, devices: [...state.devices], sort: sortEvent});
    }

    const leafSensorsAsync = useAsync({promiseFn: getLeafSensors});
    const customersAsync = useAsync({promiseFn: () => getCustomersByAncestorId(props.customerId)});
    const profilesAsync = useAsync({promiseFn: () => getProfiles(props.customerId)})

    switch (leafSensorsAsync.status) {
        case 'initial':
        case 'pending':
            return <Spinner/>;
        case 'rejected':
            return null;
        default:
    }

    switch (customersAsync.status) {
        case 'initial':
        case 'pending':
            return <Spinner/>;
        case 'rejected':
            return null;
        default:
    }

    switch (profilesAsync.status) {
        case 'initial':
        case 'pending':
            return <Spinner/>;
        case 'rejected':
            return null;
        default:
    }

    const devices: LeafSensorRowData[] = leafSensorsAsync.data;
    const customers = customersAsync.data;
    const profiles = profilesAsync.data;

    if (state.devices !== devices) {
        setState(state => ({...state, devices}));
    }

    const columnInfo: ColumnOptions<LeafSensorRowData> = {
        id: {description: "ID"},
        description: {description: "Description"},
        name: {description: "Name"},
        profileId: {
            description: "Profile",
            editable: true,
            renderer: row => <span>{profiles[row.profileId]?.description}</span>,
            editRenderer: (value, onEntryChanged) => {
                function onChange(event: ChangeEvent<HTMLSelectElement>) {
                    const profileId: string = event.target.value;
                    console.log("PROFILES", profileId, profiles);
                    onEntryChanged({...value, profileId});
                }

                return (<select onChange={onChange} value={value.profileId}>
                    {Object.values(profiles).map(p => <option
                        key={p.id}
                        value={p.id}>{p.description}</option>)}
                </select>);
            }
        },
        customer: {
            description: "Customer",
            editable: true,
            editRenderer: (value, onEntryChanged) => {
                function getCustomerById(id: string) {
                    for (const c of customers as CustomerDto[]) {
                        if (c.id === id) {
                            return c;
                        }
                    }
                    // we never arrive here.
                    return (customers as CustomerDto[])[0];

                }

                function onChange(event: ChangeEvent<HTMLSelectElement>) {
                    const customerId: string = event.target.value;
                    console.log("CUSTOMERS", customers);
                    onEntryChanged({...value, customerId, customer: getCustomerById(customerId).description});
                }

                return <select onChange={onChange} value={value.customerId}>
                    {customers.map(c => <option key={c.id}
                                                value={c.id}>{c.description}</option>)}

                </select>
            }

        },
        gps: {
            description: "Gps",
            renderer: row => <span>{row.gps && (row.gps[0] + " - " + row.gps[1])}</span>
        },
        plantId: {
            description: "Plant ID"
        },
        connected: {
            description: "Connected",
            renderer: row => <GreenRed on={row.connected}/>
        },
        hidden: {
            description: "Hidden",
            renderer: row => <span>{row.hidden ? '✓' : ''}</span>,
            // editable: true,
            editRenderer: (value, onEntryChanged) => {
                function onChange(event: ChangeEvent<HTMLInputElement>) {
                    onEntryChanged({...value, hidden: event.target.checked});
                }
                return (<input type="checkbox" checked={value.hidden} onChange={onChange} />);
            }

        }
    }

    async function onDeviceSave(device: LeafSensorRowData) {
        console.log("Moving plant", device);
        await PlantService.movePlant(device.plantId, device.customerId);

        await leafSensorsAsync.reload();
    }

    function onShowHiddenToggled(event: ChangeEvent<HTMLInputElement>) {
        setState( s => ({...s, showHidden:event.target.checked}));
    }

    const filteredDevices = state.showHidden ?  state.devices : state.devices.filter( d => !d.hidden);

    return <div>
        <Card>
            <div>Leaf Sensors  <small><label><input type="checkbox" onChange={onShowHiddenToggled} />Show hidden</label></small></div>

            <Table values={filteredDevices}
                   columns={columnInfo}
                   sortBy={state.sort}
                   editable={true}
                   onEntrySave={onDeviceSave}
                   onSortChanged={handleSortChanged}/>
        </Card>

    </div>;
}
