import React from "react";
import {ProfileService} from "../../../service/ProfileService";
import {Card} from "../../Card";
import {ColumnOptions, SortingInfo, Table} from "../../Table";
import {ProfileDto} from "../../../dto/ProfileDto";
import {useAsync} from "../../../hooks/useAsync";
import {SensorsEdit} from "./SensorsEdit";
import {SensorsView} from "./SensorsView";
import {Spinner} from "../../Spinner";
import {SensorDto} from "../../../dto/SensorDto";


interface ProfilePageState {
    sort?: SortingInfo<ProfileDto>;
}


function getColumnInfo(): ColumnOptions<ProfileDto> {
    return {
        id: {
            description: "ID"
        },
        description: {
            description: "Description",
            editable: true,
            editRenderer: (x, onEntryChanged) => (
                <input value={x.description}
                       onChange={e => onEntryChanged({...x, description: e.target.value})}></input>)
        },
        sensors: {
            description: "Sensors",
            editable: true,
            renderer: profile => <Card><span/><SensorsView sensors={profile.sensors}/></Card>,
            editRenderer: (profile, onEntryChanged) => {

                function onSensorAdded(sensor: SensorDto) {
                    const sensors = [...profile.sensors];
                    sensors.push(sensor);
                    onEntryChanged({...profile, sensors});
                }

                function onSensorDeleted( sensor: SensorDto) {
                    console.log(sensor, profile.sensors);
                    const sensors = profile.sensors.filter( s => s != sensor );
                    onEntryChanged({...profile, sensors});

                }
                function onSensorChanged(previous: SensorDto, current: SensorDto) {
                    const sensors = [...profile.sensors];
                    for (let i = 0; i < profile.sensors.length; ++i) {
                        if (profile.sensors[i] === previous) {
                            sensors[i] = current;
                        }
                    }
                    onEntryChanged({...profile, sensors});
                }
                return <Card><span/><SensorsEdit
                    sensors={profile.sensors}
                    onSensorAdded={onSensorAdded}
                    onSensorChanged={onSensorChanged}
                    onSensorDeleted={onSensorDeleted}
                /> </Card>
            }


        }
    };
}

const initialState: ProfilePageState = {}


export interface Props {
    customerId: string;
}

export function ProfilePage(props: Props) {
    const state = initialState;
    const profilesAsync = useAsync({
        promiseFn: () => ProfileService.getProfilesByParentOfCustomerId(props.customerId),
        watch: props.customerId
    });
    switch (profilesAsync.status) {
        case 'pending':
        case 'initial':
            return <Spinner/>;
        case 'rejected':
            return null;
        default:
    }

    async function onProfileSave(profile: ProfileDto) {
        await ProfileService.saveProfile(profile);
        await profilesAsync.reload();
    }

    async function onProfileDelete(profile: ProfileDto): Promise<void> {
        await ProfileService.deleteProfile(profile);
        await profilesAsync.reload();
    }

    function handleSortChanged() {

    }

    function onProfileAdd() {
        const newProfile: ProfileDto = {
            customerId: props.customerId || '',
            description: 'New Profile',
            sensors: [],

        };
        if (profilesAsync.status === 'fulfilled') {
            profilesAsync.override([...profilesAsync.data, newProfile]);
        }
    }

    const values: ProfileDto[] = profilesAsync.data;

    function isRowEditable(row: ProfileDto) {
        return row.customerId === props.customerId;
    }

    return <div >
        <Card >
            <div className="row">
                <span>Profiles</span>
                <span className="spacer"></span>
                <button onClick={onProfileAdd}>New Profile</button>
            </div>

            <Table values={values}
                   columns={getColumnInfo()}
                   sortBy={state.sort}
                   editable={isRowEditable}
                   onEntrySave={onProfileSave}
                   onEntryDelete={onProfileDelete}
                   onSortChanged={handleSortChanged}
            />
        </Card>

    </div>;
}
